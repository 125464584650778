<template>
    <div class="pt-3 pl-5 pr-5 mb-24 overflow-y-hidden">
        <div class="row w-full flex justify-between">
            <h1 class="pt-1.5">Label Definitions</h1>
            <div class="flex items-center">
                <button class="ml-3 flex" :class="labelDefinitions.length == 0 && selectedTabObj.id != 20406 ? 'btndisabled pointer-events-none' : 'filterButton'" @click="filterOpen()">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div>
        </div>
        <div class="mt-3 fontSizeInPage">
                Data organization is the practice of categorizing and classifying data to make it more usable. Similar to a file folder, where we keep important documents,you'll need to arrange your data in the most logical and orderly fashion,so you -- and anyone else who accesses it --can easily
                find what they're looking for.Data organization is the practice of categorizing and classifyingdata to make it more usable. Similar to a file folder, where we keep important documents ,you'll need to arrange your data in the most logical and orderly fashion.
        </div>
        <div>
            <div class="mt-5 px-5 pb-5 bg-white w-full border-lightgrey relative pt-5 rounded">
               
                <div class="group-buttons" v-if="isSmallScreen">
                    <!-- Group navigation buttons for smaller screens -->
                    <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                        <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                    </div>
                    <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === tabHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                        <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                    </div>
                </div>
                <div v-else>
                    <!-- Scroll buttons for larger screens -->
                    <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': !canScrollLeft && !isSmallScreen }">
                        <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                    </div>
                    <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': !canScrollRight && !isSmallScreen }">
                        <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                    </div>
                </div>
                <div class="w-full">
                    <div class="flex flex-row borderBottom justify-between cursor-pointer tab-container" ref="tabContainer">
                        <div class="pb-1 text-sm" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold primary':'text-sm text-disabledgrey'" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab)">
                            <div>
                                <div ref="actualIndex" class="cursor-pointer tab-name p-0.75">{{ tab.displayName }}</div>
                                <div :class="{ progress: selectedTabIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
 
               <div class="progressbarB h-1 rounded"></div>
                <div>
                       
                    <div v-if="selectedTabObj.id == 20406">
                        
                        <div class="font-semibold mt-3">{{ 'Impact' }}</div>
                        <div class="mb-7">
                            <newTable :tableRowData="overAllData" :headersData="overAllHeaders" :loadData="dataLoading"></newTable>
                           
                        </div> 
                        
                        <div class="font-semibold mt-3">{{ 'Operations' }}</div>
                        <div class="mb-7">
                            <newTable :tableRowData="operationsData" :headersData="opsHeaders" :loadData="dataLoading"></newTable>
                           
                        </div>
 
                        <!-- <div class="font-semibold mt-3">{{ 'Organisation' }}</div>
                        <div class="mb-7">
                            <newTable :tableRowData="organizationData" :headersData="orgHeaders"></newTable>
                           
                        </div> -->
 
                        <div class="font-semibold mt-3">{{ 'Strategic' }}</div>
                        <div class="mb-7">
                            <newTable :tableRowData="businessData" :headersData="businessHeaders" :loadData="dataLoading"></newTable>
                           
                        </div>
 
                        <!-- <div class="font-semibold mt-3">{{ 'Techincal' }}</div>
                        <div class="mb-7">
                            <newTable :tableRowData="technicalData" :headersData="technicalHeaders"></newTable>
                           
                        </div> -->
 
                        <div class="font-semibold mt-3">{{ 'Financial' }}</div>
                        <div class="mb-7">
                            <newTable :tableRowData="financialData" :headersData="financialHeaders" :loadData="dataLoading"></newTable>
                           
                        </div>
 
                        <div class="font-semibold mt-3">{{ 'Compliance' }}</div>
                        <div class="mb-7">
                            <newTable :tableRowData="legalData" :headersData="LegalHeaders" :loadData="dataLoading"></newTable>
                           
                        </div>
 
 
                    </div>
                    <div v-else>
                        <newTable :tableRowData="labelDefinitions" :headersData="Headers" :loadData="dataLoading"></newTable>
                    </div>
 
                    <div v-if="toolTipActive">
                        <tool-tip :tipText="toolTipText"></tool-tip>
                    </div>
                       
                </div>
 
                <popup v-if="showPopUp" :popUp="showPopUp">
                    <template v-slot:header>
                        <div class="flex items-center justify-between popupheadingcontainer rounded">
                            <h1 v-if="selectedTabObj.content == 'Impact'">{{ selectedTabObj.content }} - {{ dummylabelobject.value }}</h1>
                            <h1 v-else>{{ selectedTabObj.content }}</h1>
                            <button class="float-right text-3xl" @click="onCancelButtonPopUp('close')">
                                <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                            </button>
                        </div>
                    </template>
                   
                    <fieldset>
                        <div>
                            <div class="popupbodycontainer">
                                <div class="statuscontainer">
                                    <div class="w-6/12">
                                        <div>
                                            <label class="controllabel upperspacing text-sm">Label</label>
                                            <input
                                                type="text"
                                                class="inputboxstyling"
                                                v-model.trim="labelobject.label"
                                                @input ="restrictCharacters()"
                                                placeholder="Label"
                                                :class="{
                                                    'is-invalid borderRed': (v$.labelobject.label.$error || displayErrorMessage == true || labelExist)
                                                }"
                                            />
                                            <div v-if="v$.labelobject.label.$error || labelExist" class="text-red-500">
                                                <div class="error-text" v-if="v$.labelobject.label.required.$invalid">Required</div>
                                                <div v-else-if="v$.labelobject.label.required && v$.labelobject.label.validation2.$invalid" class="invalid-feedback">2logic</div>
                                                <div v-else-if="v$.labelobject.label.required && v$.labelobject.label.validation20.$invalid" class="invalid-feedback">20logic</div>
                                                <div v-else-if="labelExist" class="invalid-feedback text-xs">Label already exist</div>
                                            </div>
                                            <div v-else-if="displayErrorMessage == true">
                                                <div class="text-red-500 text-xs">Character Limit is exceeded</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing">
                                    <div>
                                        <label class="controllabel text-sm">Description</label>
                                        <textarea class="inputboxstyling"
                                         v-model.trim="labelobject.description"
                                          placeholder="Description" rows="4" 
                                          :class="{'is-invalid borderRed': v$.labelobject.description.$error || descriptionExceed}"
                                        />
                                        <div v-if="v$.labelobject.description.$error || descriptionExceed" class="text-red-500">
                                            <div class="error-text" v-if="v$.labelobject.description.required.$invalid">Required</div>
                                            <div v-if="descriptionExceed" class="invalid-feedback text-xs">Description Limit is 300 charecters</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="buttonposition flex justify-center items-center mt-2 mb-4 mx-0">
                            <button class="mr-1.5" :disabled="clickOnButton" :class="JSON.stringify(labelobject) == JSON.stringify(dummylabelobject) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="resetlabeldata()">Cancel</button>
                            <button :disabled="clickOnButton" :class="JSON.stringify(labelobject) == JSON.stringify(dummylabelobject) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="savelabeldata()">Save</button>
                        </div>
                    </fieldset>
                </popup>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import newTable from '@/components/newTable.vue';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { emitter, toast } from '@/main';
import popup from '@/components/popup.vue';
import store from '@/store';
import toolTip from '@/components/toolTip.vue'
import { required, maxLength, minLength, email, helpers, requiredIf } from '@vuelidate/validators';
const validation2 = helpers.regex(/^[a-zA-Z0-9\s]{1,2}$/);
const validation20 = helpers.regex(/^[a-zA-Z0-9\s]{1,20}$/);
 
export default defineComponent({
    data(): any {
        return {
            showFilters: false,
            Headers: [],
            selectedTabObj: { displayName: 'Confidentiality', id: 20401, content: 'Confidentiality' },
            selectedTabIndex: null,
            v$: useVuelidate(),
            labelobject: {
                label: '',
                description: ''
            },
            dummylabelobject: {},
            filteredInformations: [],
            labelDefinitions: [],
            showPopUp: false,
            openPopup: '',
            opsHeaders: ['opsLabel', 'opsValue', 'description'],
            orgHeaders: ['orgLabel', 'orgValue', 'description'],
            businessHeaders: ['businessLabel', 'businessValue', 'description'],
            financialHeaders: ['finLabel', 'finValue', 'description'],
            LegalHeaders: ['legalLabel', 'legalValue', 'description'],
            technicalHeaders: ['techLabel', 'techValue', 'description'],
             overAllHeaders:['overAllLabel', 'overAllValue', 'description'],
            headersArray: [
                {
                    id: 20401,
                    headers: ['confLabel', 'confValue', 'description']
                },
                {
                    id: 20402,
                    headers: ['intLabel', 'intValue', 'description']
                },
                {
                    id: 20403,
                    headers: ['avaiLabel', 'avaiValue', 'description']
                },
                {
                    id: 20404,
                    headers: ['scLabel', 'scValue', 'description']
                },
                {
                    id: 20405,
                    headers: ['likeLabel', 'likeValue', 'description']
                },
                {
                    id: 20406,
                    headers: ['opsLabel', 'opsValue', 'description']
                },
                {
                    id: 20407,
                    headers: ['orgLabel', 'orgValue', 'description']
                },
                {
                    id: 20408,
                    headers: ['businessLabel', 'businessValue', 'description']
                },
                {
                    id: 20409,
                    headers: ['techLabel', 'techValue', 'description']
                },
                {
                    id: 20410,
                    headers: ['finLabel', 'finValue', 'description']
                },
                {
                    id: 20411,
                    headers: ['legalLabel', 'legalValue', 'description']
                },
                {
                    id: 20412,
                    headers: ['sevLabel', 'severitypreview', 'description']
                },
                {
                    id: 20413,
                    headers: ['prioLabel', 'prioritypreview', 'description']
                },
                {
                    id: 20414,
                    headers: ['infoClassLabel', 'infoClassValue', 'description']
                },
                {
                    id: 20415,
                    headers: ['proCLabel', 'proCValue', 'description']
                },
                {
                    id: 20416,
                    headers: ['overAllLabel', 'overAllValue', 'description']
                }
            ],
            tabHead: [
                { displayName: 'Confidentiality', id: 20401, content: 'Confidentiality' },
 
                { displayName: 'Integrity', id: 20402, content: 'Integrity' },
 
                { displayName: 'Availability', id: 20403, content: 'Availability' },
 
                { displayName: 'Security Category', id: 20404, content: 'Security Category' },
 
                { displayName: 'Likelihood', id: 20405, content: 'Likelihood' },
 
                { displayName: 'Impact', id: 20406, content: 'Impact' },
 
                { displayName: 'Severity', id: 20412, content: 'Severity' },
 
                { displayName: 'Priority', id: 20413, content: 'Priority' },
 
                { displayName: 'Information Classification', id: 20414, content: 'Information Classification' },
 
                { displayName: 'Process Criticality', id: 20415, content: 'Process Criticality' },
 
                // { displayName: 'Overall Impact', id: 20416, content: 'OverAll Impact' }
            ],
            operationsData: [],
            organizationData: [],
            businessData: [],
            technicalData: [],
            legalData: [],
            financialData: [],
            overAllData:[],
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0 ,// Total number of groups for small screens
            displayErrorMessage : false,
            toolTipActive : false,
            toolTipText : '',
            tabsPerScreen: 5, // Default value; will be updated on resize
            dataLoading:true,
            presentLabelsArray:[],
            clickOnButton: false,
        };
    },
    computed:{
        labelExist: function (): any {
           let a = false;
           a = this.presentLabelsArray.includes((this.labelobject.label.trim()).toLowerCase());
           return a
        },
        descriptionExceed:function():any{
            if(this.labelobject.description.length > 300){
                return true
            }else{
                return false
            }
        }
    },
    validations() {
        return {
            labelobject: {
                label: {
                    required,
                    validation2: requiredIf(() => {
                        return this.selectedTabIndex == 20413;
                    }),
                    validation20: requiredIf(() => {
                        return this.selectedTabIndex != 20413;
                    })
                },
                description: {
                    required
                }
            }
        };
    },
    components: {
        newTable,
        popup,
        toolTip
    },
    methods: {
        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
       toggleTabs(tab:any) {
            sessionStorage.setItem('selectedTab', tab.id);
            this.selectedTabIndex = tab.id;
            this.selectedTabObj = tab;
            this.dataLoading = true;

            const promises = [];

            if (this.selectedTabIndex === 20406) {
                const tabIds = [20406, 20408, 20410, 20411, 20416];
                
                // Collect all promises
                tabIds.forEach(id => {
                    promises.push(this.getLabelEntityData(id));
                });
            } else {
                promises.push(this.getLabelEntityData(this.selectedTabIndex));
            }

            // Use Promise.all() to handle the array of promises
            Promise.all(promises)
                .then(() => {
                    // Handle success
                    console.log("Data loaded successfully");
                })
                .catch(error => {
                    // Handle any error
                    console.error('Error fetching data:', error);
                })
                .finally(() => {
                    // Finalize loading state
                    this.showFilters = false;
                    emitter.emit('visible', this.showFilters);
                    this.dataLoading = false;
                });
       },
        onCancelButtonPopUp() {
            this.showPopUp = false;
            this.labelobject = {};
             this.displayErrorMessage = false
        },
        resetlabeldata() {
            this.labelobject = { ...this.dummylabelobject };
             this.displayErrorMessage = false
        },
        restrictCharacters(){
              if(this.labelobject.label.length > 12){
                this.displayErrorMessage = true;
              }else if(this.labelobject.label.length < 12 || this.labelobject.label.length == 12){
                this.displayErrorMessage = false;
              }
        },
        async savelabeldata() {
            this.v$.$touch();
            if (!this.v$.labelobject.$invalid  && this.displayErrorMessage == false && this.labelExist == false && this.descriptionExceed == false) {
                this.clickOnButton = true;

                let payload: any = {
                    key: this.labelobject.label,
                    value: this.labelobject.value,
                    description: this.labelobject.description
                };
 
                try {
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/label/${this.labelobject.labelTypeId}/update`, payload).then(async (result: any) => {
                        toast.info('Updated', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                        await this.getLabelEntityData(this.labelobject.labelTypeId);
                        //updating store after updating the labels
                        await store.dispatch('getLabelsInfo');
                        this.showPopUp = false;
                        this.labelobject = {};
                    });
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } finally {
                    this.clickOnButton = false;
                }
            }
        },
         onclickTableRowData(Data: any){
            let findHeaders:any = (this.headersArray.find((obj:any)=>{return obj.id == Data.labelTypeId})).headers;
            let key1 = findHeaders[0];
            let key2 = findHeaders[1];
 
            let obj = {
                label: Data[key1].trim(),
                value: Data[key2],
                description: Data.description,
                labelTypeId: Data.labelTypeId
            };
            console.log('OBJ',obj);
           
            this.labelobject = { ...obj };
            this.dummylabelobject = { ...obj };
            this.showPopUp = true;
        },
        async getLabelEntityData(labelEntityId: any) {
            let finalArray: any = [];
            this.labelDefinitions = [];
 
            let findHeaders: any = this.headersArray.find((obj: any) => {
                return obj.id == labelEntityId;
            });
            console.log('iudciuennonciosn', findHeaders);
            this.Headers = findHeaders.headers;
 
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/label/${labelEntityId}/getall`).then((result: any) => {
                console.log('res.dataaaa', result.data);
                result.data[0].values.map((dbObj: any) => {
                    let sendObj: any = {};
 
                    sendObj = {
                        _id: dbObj._id,
                        [findHeaders.headers[0]]: dbObj.key,
                        [findHeaders.headers[1]]: dbObj.value,
                        description: dbObj.description,
                        labelTypeId: labelEntityId
                    };
 
                    finalArray.push(sendObj);
                });
            });
 
            if (labelEntityId == 20406) {
                this.operationsData = [...finalArray];
            } else if (labelEntityId == 20407) {
                this.organizationData = [...finalArray];
            } else if (labelEntityId == 20408) {
                this.businessData = [...finalArray];
            } else if (labelEntityId == 20409) {
                this.technicalData = [...finalArray];
            } else if (labelEntityId == 20410) {
                this.financialData = [...finalArray];
            } else if (labelEntityId == 20411) {
                this.legalData = [...finalArray];
            } else if(labelEntityId==20416){
                this.overAllData=[...finalArray];
            }
            else {
                this.labelDefinitions = [...finalArray];
            }
           
        },
        
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
       
        scrollTabs(direction:any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                this.currentGroupIndex++;
            }
            this.updateVisibleTabs('tab');
        },
 
        handleResize() {
            const width = window.innerWidth;
            if (width <= 427) {
                this.tabsPerScreen = 1;
            } else if (width <= 569) {  
                this.tabsPerScreen = 3;
            } else if (width <= 1024) {
                this.tabsPerScreen = 5;
            } else {
                this.tabsPerScreen = this.tabHead.length; // Show all tabs for larger screens
            }

            this.isSmallScreen = (width <= 1024); // For logic buttons ref

            // Calculate total groups
            this.totalGroups = Math.ceil(this.tabHead.length / this.tabsPerScreen);

            // Determine the group containing the selected tab
            this.updateCurrentGroupIndex();
            this.updateVisibleTabs('normal');
            this.updateButtonStates();
        },

        updateCurrentGroupIndex() {
            const selectedTabIndex = this.tabHead.findIndex((tab:any) => tab.id === this.selectedTabIndex);
            if (selectedTabIndex >= 0) {
                this.currentGroupIndex = Math.floor(selectedTabIndex / this.tabsPerScreen);
            } else {
                this.currentGroupIndex = 0; // Reset to first group if selected tab not found
            }
        },

        updateVisibleTabs(from:any) {
            if (this.tabsPerScreen === this.tabHead.length) {
                this.visibleTabs = [...this.tabHead]; // Show all tabs
            } else {
                const startIdx = this.currentGroupIndex * this.tabsPerScreen;
                const endIdx = startIdx + this.tabsPerScreen;
                this.visibleTabs = this.tabHead.slice(startIdx, endIdx);
            }

            if(from=='tab'){
                this.toggleTabs(this.visibleTabs[0])
            }
        },
    },
    async created() {
        this.dataLoading = true;
        const savedTabKey = sessionStorage.getItem('selectedTab');
        if (savedTabKey) {
            const savedTab = this.tabHead.find((tab: any) => tab.id == savedTabKey);
            if (savedTab) {
                await this.toggleTabs(savedTab);
            }
            else {
                this.toggleTabs({ displayName: 'Confidentiality', id: 20401, content: 'Confidentiality' });
                this.selectedTabIndex = 20401;
            }
        }
        else {
            this.toggleTabs({ displayName: 'Confidentiality', id: 20401, content: 'Confidentiality' });
            this.selectedTabIndex = 20401;
        }
    },
    async mounted() {
        this.visibleTabs = [...this.tabHead];
 
        this.handleResize(); // Initial setup
        window.addEventListener('resize', this.handleResize);
        this.updateButtonStates(); // Initial update to button states
 
 
        emitter.off('dataTransfering');
        emitter.on('dataTransfering', (data: any) => {

            this.presentLabelsArray = []
            let presentArray:any = []

            let impactTab:any = [20406, 20407, 20408, 20409, 20410 ,20411, 20416];

            if(impactTab.includes(data.labelTypeId)){
                switch (data.labelTypeId) {
                    case 20406:
                        presentArray = this.operationsData;
                        break;
                    case 20407:
                        presentArray = this.organizationData;
                        break;
                    case 20408:
                        presentArray = this.businessData;
                        break;
                    case 20409:
                        presentArray = this.technicalData;
                        break;
                    case 20410:
                        presentArray = this.financialData;
                        break;
                    case 20411:
                        presentArray = this.legalData;
                        break;
                    case 20416:
                        presentArray = this.overAllData;
                        break;
                    default:
                        presentArray = [];
                }
            }else{
                presentArray= this.labelDefinitions
            }
            this.dataLoading = false;
            let presentKey:any = Object.keys(presentArray[0]).find((key:any)=>{return key.includes('Label')});

            presentArray.map((obj:any)=>{
                if(data[presentKey] != obj[presentKey]){
                    this.presentLabelsArray.push(obj[presentKey].toLowerCase())
                }
            })


            console.log('aedoinesfne',this.presentLabelsArray)

            this.onclickTableRowData(data);
        });

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab')
    },
    });
</script>
 <style scoped>
/* .progress {
    background: #152a43;
    top: 2px;
}
.progressbarB {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    position: relative;
    top: -6px;
} */
.columnGap{
     gap: 3%;
}
.primary{
    color:#152a43 !important;
}
</style>
 
 
 
 
 